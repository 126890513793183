import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import styles from './styles.module.scss';

const SubscribeCTA = ({ heading, label, buttonLink }) => (
  <div className={cx('text-center', styles.base)}>
    <div className="container d-flex d-center">
      <h3>{heading}</h3>
      <Link to={buttonLink} className="btn btn--light btn--large">
        {label}
      </Link>
    </div>
  </div>
);

export default SubscribeCTA;
